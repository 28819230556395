import { Chip } from '@mui/material';
import React from 'react';

const BetaTag = () => {
  return (
    <Chip
      label="Beta"
      size="small"
      sx={{ marginLeft: '8px', fontSize: '14px', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' }}
    />
  );
};

export default BetaTag;
