import React, { FormEvent, useEffect, useState } from 'react';
import { Dialog, DialogTitle, Divider, DialogContent, Box, DialogActions, Button } from '@mui/material';
import ErrorTable from './ErrorTable';
import ErrorBox from '../../../../components/shared/boxes/ErrorBox';
import ConfirmationDialog from '../../../../components/shared/modals/ConfirmationDialog';
import Paragraph from '../../../../components/shared/typography/Paragraph';

interface SyncErrorsModalProps {
  modalOpen: boolean;
  onClose: (s: boolean) => void;
  onSuccess: (responseJson: any) => {};
  tryAgainApiUrl?: string;
  tryAgainApiMethod?: string;
  errorsHref: {
    recentErrorHref?: string;
    customerErrorHref?: string;
    partErrorHref?: string;
    serviceErrorHref?: string;
    workErrorHref?: string;
  };
}

const SyncErrorsModal: React.FC<SyncErrorsModalProps> = ({ ...props }) => {
  const { modalOpen, onClose, onSuccess, tryAgainApiUrl, tryAgainApiMethod, errorsHref } = props;
  const [apiError, setApiError] = useState<{ errorMessage?: string }>({});
  const [openConfirmationDialog, updateConfirmationDialogVisibility] = useState<boolean>(false);
  const errorsKeys = Object.keys(errorsHref).filter((k) => errorsHref[k]);

  const defineTableTitleByErrorKey = {
    recentErrorHref: 'Erros recentes',
    customerErrorHref: 'Erros - Clientes',
    partErrorHref: 'Erros - Peças / Produtos',
    serviceErrorHref: 'Erros - Serviços',
    workErrorHref: 'Erros - Atividades / Ordens de Serviço',
  };

  const tryAgain = async () => {
    try {
      const response = await fetch(tryAgainApiUrl || '', {
        method: tryAgainApiMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();

      if (!response.ok) {
        const errorMessage = `Error ${response.status}: ${responseJson.error}`;
        updateConfirmationDialogVisibility(false);
        return setApiError({ errorMessage: errorMessage });
      }

      updateConfirmationDialogVisibility(false);
      onClose(false);
      onSuccess(responseJson);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
      setApiError({});
    };
  }, []);

  return (
    <Dialog fullWidth open={modalOpen} onClose={() => onClose(false)} maxWidth="md">
      <Box sx={{ padding: '16px', position: 'relative' }}>
        <DialogTitle sx={{ textAlign: 'center' }}>Erros</DialogTitle>
      </Box>

      <Divider />

      <DialogContent sx={{ padding: '16px', display: 'flex', flexDirection: 'column', gap: '64px' }}>
        {apiError.errorMessage && <ErrorBox errorMessage={apiError.errorMessage} onClose={() => setApiError({})} />}

        {errorsKeys.map((key) => (
          <ErrorTable
            key={key}
            tableTitle={defineTableTitleByErrorKey[key]}
            entityName={key}
            apiUrl={errorsHref[key]}
          />
        ))}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ padding: '16px', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          size="medium"
          color="error"
          onClick={() => onClose(false)}
          type="button"
          autoFocus={false}
        >
          Cancelar
        </Button>

        <Button
          id="omie-disconnect-modal-disconnect-btn"
          variant="contained"
          size="medium"
          color="success"
          type="button"
          autoFocus={false}
          onClick={() => updateConfirmationDialogVisibility(true)}
          disabled={openConfirmationDialog}
        >
          Tentar novamente
        </Button>
      </DialogActions>

      <ConfirmationDialog
        modalOpen={openConfirmationDialog}
        onClose={(v) => updateConfirmationDialogVisibility(v)}
        onSubmit={() => tryAgain()}
        confirmationBtnTitle={'Já corrigi os erros'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Paragraph>Os erros precisam ser corrigidos antes de tentar vincular os sistemas novamente</Paragraph>
        </Box>
      </ConfirmationDialog>
    </Dialog>
  );
};

export default SyncErrorsModal;
