import React from 'react';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import MainTooltip from '../../../../components/shared/others/MainTooltip';
import Paragraph from '../../../../components/shared/typography/Paragraph';

interface SynchronizationModalProps {
  modalOpen: boolean;
  onClose: (s: boolean) => void;
}

const SynchronizationModal: React.FC<SynchronizationModalProps> = ({ modalOpen, onClose }) => {
  const syncStatuses = [
    { status: 'done', produttivoName: 'Clientes', omieName: 'Clientes' },
    { status: 'done', produttivoName: 'Peças e materiais', omieName: 'Produto' },
    { status: 'done', produttivoName: 'Serviços', omieName: 'Serviços' },
    { status: 'done', produttivoName: 'Atividade', omieName: 'Ordem de serviço' },
  ];

  // há um bug na versão do 5.15.5 do MUI em que não considera o color="success" em Typography
  const syncComponentByStatus = {
    done: () => (
      <Typography color="success.main">
        <CheckIcon /> Sincronizado
      </Typography>
    ),
  };

  return (
    <Dialog fullWidth open={modalOpen} onClose={() => onClose(false)} maxWidth="lg">
      <Box sx={{ padding: '16px', position: 'relative' }}>
        <DialogTitle sx={{ textAlign: 'center' }}>Sincronização</DialogTitle>
        <MainTooltip title={'Fechar'}>
          <IconButton
            aria-label="Fechar modal"
            onClick={() => onClose(false)}
            autoFocus={false}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </MainTooltip>
      </Box>

      <Divider />

      <DialogContent sx={{ padding: '16px', display: 'flex', justifyContent: 'center' }}>
        <TableContainer
          aria-label="tabela de status de itens sincronizados no Produttivo e na Omie"
          sx={{ maxWidth: '896px' }}
        >
          <Table sx={{ border: '1px solid #D2D2D2' }}>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Produttivo</TableCell>
                <TableCell>Omie</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {syncStatuses.map((syncStatus) => (
                <TableRow key={syncStatus.produttivoName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    {syncComponentByStatus[syncStatus.status] && syncComponentByStatus[syncStatus.status]()}
                  </TableCell>
                  <TableCell>
                    <Paragraph>{syncStatus.produttivoName}</Paragraph>
                  </TableCell>
                  <TableCell>
                    <Paragraph>{syncStatus.omieName}</Paragraph>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default SynchronizationModal;
