import { createRoot } from 'react-dom/client';
import OtherSection from './integration/others/OtherSection';
import SectionWithTabs from '../components/shared/sections/SectionsWithTabs';
import ERPSection from './integration/erp/ERPSection';
import { createTheme, ThemeProvider } from '@mui/material';
import { ptBR } from '@mui/material/locale';

const produttivoMuiThemeV1 = createTheme(
  {
    palette: {
      primary: {
        main: '#01a32d',
      },
      success: {
        main: '#01a32d',
      },
      warning: {
        main: '#EA620E',
      },
      info: {
        main: '#1D64F3',
      },
      error: {
        main: '#E41324',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            'text-transform': 'none',
            '&.MuiButton-outlinedSuccess': {
              'border-color': '#D2D2D2',
            },
          },
          sizeMedium: {
            fontSize: '1rem',
            minWidth: '80px',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            alignItems: 'baseline',
          },
        },
      },
    },
  },
  ptBR
);

const Integration = () => {
  const sectionTabs = [
    { value: 'erp', label: 'ERP', component: <ERPSection />, beta: true },
    { value: 'others', label: 'Outras', component: <OtherSection /> },
  ];

  return <SectionWithTabs tabs={sectionTabs} />;
};

const integrationContainer = document.getElementById('integration-container');

if (integrationContainer !== null) {
  const root = createRoot(integrationContainer);

  root.render(
    <ThemeProvider theme={produttivoMuiThemeV1}>
      <Integration />
    </ThemeProvider>
  );
}
