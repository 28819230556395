import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';

interface ConfirmationDialogProps {
  modalOpen: boolean;
  onClose: (s: boolean) => void;
  onSubmit: () => void;
  dialogTitleContent?: React.ReactNode;
  children: React.ReactNode;
  confirmationBtnAriaLabel?: string;
  confirmationBtnTitle?: React.ReactNode;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ ...props }) => {
  const { modalOpen, onClose, onSubmit, dialogTitleContent, children, confirmationBtnAriaLabel, confirmationBtnTitle } =
    props;

  return (
    <Dialog fullWidth open={modalOpen} onClose={() => onClose(false)} maxWidth="xs">
      {dialogTitleContent && (
        <>
          <DialogTitle sx={{ padding: '16px', textAlign: 'center' }}>{dialogTitleContent}</DialogTitle>
          <Divider />
        </>
      )}

      <DialogContent
        sx={{
          display: 'flex',
          maxWidth: '424px',
          margin: 'auto',
          padding: '16px',
          flexDirection: 'column',
          gap: '8px',
          justifyContent: 'center',
        }}
      >
        {children}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ padding: '8px', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button
          variant="text"
          size="medium"
          color="error"
          onClick={() => onClose(false)}
          type="button"
          autoFocus={false}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          size="medium"
          color="success"
          type="button"
          aria-label={confirmationBtnAriaLabel}
          autoFocus={false}
          onClick={() => onSubmit()}
        >
          {confirmationBtnTitle || 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
