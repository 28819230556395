import React from 'react';
import { Alert, AlertProps, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MainTooltip from '../others/MainTooltip';

interface ErrorBoxProps extends AlertProps {
  errorMessage: string;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ errorMessage, onClose }) => {
  return (
    <Alert
      severity="error"
      sx={{ alignItems: 'center' }}
      onClose={onClose}
      action={
        <MainTooltip title="Fechar">
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </MainTooltip>
      }
    >
      {errorMessage}
    </Alert>
  );
};

export default ErrorBox;
