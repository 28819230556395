import { Box, Card, CardActions, CardContent, CardMedia, SxProps } from '@mui/material';
import React from 'react';
import Paragraph from '../typography/Paragraph';
import SecondaryLink from '../links/SecondaryLink';

interface InfoCardProps {
  title?: string;
  subtitle?: string;
  img: any;
  linkTitle?: string;
  linkHref?: string;
  linkOptions?: {
    targetBlank?: boolean;
    thirdPartyLink?: boolean;
  };
  headerComponent?: React.ReactNode;
  bodyComponent?: React.ReactNode;
  ImageProps?: {
    sx?: SxProps;
  };
}

const InfoCard: React.FC<InfoCardProps> = ({ ...props }) => {
  const { title, subtitle, img, linkTitle, linkHref, linkOptions, headerComponent, bodyComponent, ImageProps } = props;

  return (
    <Card
      sx={{
        padding: '24px',
        backgroundColor: '#FFF',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        boxShadow: 'unset',
      }}
    >
      <CardContent sx={{ padding: 0, marginBottom: '16px' }}>
        {headerComponent && headerComponent}

        {title && (
          <Paragraph sx={{ fontSize: '16px', marginBottom: '4px' }}>
            <strong>{title}</strong>
          </Paragraph>
        )}

        {subtitle && <Paragraph>{subtitle}</Paragraph>}
      </CardContent>
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
        <CardMedia
          component="img"
          image={img}
          sx={{ 'border-radius': '8px', height: '90px', width: '90px', objectFit: 'contain', ...ImageProps?.sx }}
        />
        <CardActions
          sx={{
            padding: 0,
            margin: 'auto',
            flexDirection: 'column',
            alignItems: 'baseline',
            justifyContent: 'center',
            flex: 1,
            flexWrap: 'wrap',
          }}
        >
          {bodyComponent && bodyComponent}

          {linkHref && linkTitle && (
            <SecondaryLink href={linkHref} {...linkOptions}>
              {linkTitle}
            </SecondaryLink>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};

export default InfoCard;
