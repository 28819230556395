import React, { useEffect, useRef, useState } from 'react';
import omieLogo from '@/images/omie_logo.png';
import InfoCard from '../../../../components/shared/boxes/InfoCard';
import { Box, Button, Link, Typography } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';
import Paragraph from '../../../../components/shared/typography/Paragraph';
import AuthModal from './AuthModal';
import SynchronizationModal from './SynchronizationModal';
import DisconnectModal from './DisconnectModal';
import SyncErrorsModal from './SyncErrorsModal';
import { toCamelCase } from '../../../helpers/helpers';
import ErrorBox from '../../../../components/shared/boxes/ErrorBox';
import MainTooltip from '../../../../components/shared/others/MainTooltip';

const OmieCard = ({ handleUpdateContractedModule }) => {
  const [showAuthModal, updateAuthModal] = useState(false);
  const [showSynchronizationModal, updateSynchronizationModal] = useState(false);
  const [showSyncErrorsModal, updateSyncErrorsModal] = useState(false);
  const [showDisconnectModal, updateDisconnectModal] = useState(false);
  const [apiError, setApiError] = useState({});
  const [data, setData] = useState({
    connectionStatus: undefined,
    omieAuthSecret: undefined,
    omieAuthKey: undefined,
    billingOn: undefined,
  });
  const [permissions, setPermissions] = useState({});
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const intervalRef = useRef(null);
  const integrationStatus = data?.connectionStatus;

  const stopPolling = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const fetchPoolingData = async (url) => {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const contentType = response.headers.get('content-type');

    if (!contentType || !contentType.includes('application/json')) {
      throw new TypeError("Oops, we haven't got JSON!");
    }

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = `Error ${response.status}: ${responseJson.error}`;
      return setApiError({
        errorMessage: errorMessage,
      });
    }

    updateDataAfterAPIRequest(responseJson);
  };

  const startPolling = () => {
    stopPolling();
    intervalRef.current = setInterval(() => fetchPoolingData(permissions?.integrationStatus?.href), 10000); // Poll every 10
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/integrations/erp/omie.json');

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();

      if (!response.ok) {
        const errorMessage = `Error ${response.status}: ${responseJson.error}`;
        return setApiError({
          errorMessage: errorMessage,
        });
      }

      updateDataAfterAPIRequest(responseJson);
    };

    fetchData();
    setIsPollingEnabled(true);

    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (permissions?.integrationStatus?.available) {
      if (isPollingEnabled) {
        startPolling();
        setIsPollingEnabled(false);
      }
    } else {
      stopPolling();
    }
  }, [permissions?.integrationStatus?.available, isPollingEnabled]);

  const updateDataAfterAPIRequest = (responseJson) => {
    const responsePermissions = {};
    responseJson.links.forEach((link) => {
      responsePermissions[toCamelCase(link.rel)] = { ...link, available: true };
    });

    const apiData = {
      connectionStatus: toCamelCase(responseJson?.status || 'not_connected'),
    };

    if (responseJson?.omie_auth_key) {
      apiData.omieAuthKey = responseJson?.omie_auth_key;
    }

    if (responseJson?.omie_auth_secret) {
      apiData.omieAuthSecret = responseJson?.omie_auth_secret;
    }

    if (responseJson?.generate_billing_on) {
      apiData.billingOn = responseJson?.generate_billing_on;
    }

    setData({ ...data, ...apiData });

    setPermissions(responsePermissions);

    if (apiData.connectionStatus) {
      if (apiData.connectionStatus === 'notContracted') {
        handleUpdateContractedModule('none');
      } else {
        handleUpdateContractedModule('omie');
      }
    }
  };

  const updateDataAfterAPIRequestAndAllowPooling = (responseJson) => {
    updateDataAfterAPIRequest(responseJson);
    setIsPollingEnabled(true);
  };

  function handleVisualizeButtonClick() {
    if (integrationStatus === 'done') {
      updateSynchronizationModal(true);
    } else if (integrationStatus === 'pendingWithErrors') {
      updateSyncErrorsModal(true);
    }
  }

  function defineDisabledConnectionButtonText(buttonEnabled, loadingDisabledText = '') {
    if (integrationStatus === 'running') {
      return loadingDisabledText;
    }

    if (!buttonEnabled) {
      return 'Você não tem o módulo “Integração com ERP” contratado';
    }
  }

  const canOpenVisualizeModal = integrationStatus === 'done' || integrationStatus === 'pendingWithErrors';
  const configureButtonEnable = permissions?.configureIntegration?.available;
  const loadingDisabledText = 'Aguarde a integração ser concluída';

  const configureButtonDisabledTitle = defineDisabledConnectionButtonText(configureButtonEnable, loadingDisabledText);

  const headerComponentLayout = {
    running: () => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <RotateLeftIcon color="info" />
        <Box>
          <Typography color="info.main">
            <strong>Conectando...</strong>
          </Typography>
          <Paragraph>
            Por favor, aguarde enquanto estamos recebendo e enviando dados entre os sistemas.
          </Paragraph>
        </Box>
      </Box>
    ),
    done: () => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <CheckIcon color="success" />
        <Box>
          <Typography color="success.main">
            <strong>Conectado</strong>
          </Typography>
          <Paragraph>Os sistemas estão recebendo e enviando dados com sucesso.</Paragraph>
        </Box>
      </Box>
    ),
    pendingWithErrors: () => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <WarningIcon color="warning" />
        <Box>
          <Typography color="warning.main">
            <strong>Conectado com erro</strong>
          </Typography>
          <Paragraph>Alguns itens estão impedindo que os sistemas se conectem com sucesso.</Paragraph>
          <Link onClick={() => updateSyncErrorsModal(true)} component="button" color="warning.main">
            <strong>Visualizar erros</strong>
          </Link>
        </Box>
      </Box>
    ),
    notSynchronizedWithErrors: () => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <ReportIcon color="error" />
        <Box>
          <Typography color="error.main">
            <strong>Não conectado</strong>
          </Typography>
          <Paragraph>Os dados de autenticação informados são inválidos.</Paragraph>
          <Link onClick={() => updateAuthModal(true)} component="button" color="error">
            <strong>Revisar dados</strong>
          </Link>
        </Box>
      </Box>
    ),
    notConnected: () => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <ReportIcon color="error" />
        <Box>
          <Typography color="error.main">
            <strong>Não conectado</strong>
          </Typography>
          <Paragraph>
            Erro interno no servidor do ERP, caso o problema persista entrar em contato com o suporte do sistema.
          </Paragraph>
        </Box>
      </Box>
    ),
  };

  const canSeeVisibleAndDisconnectButtons =
    integrationStatus && integrationStatus !== 'notContracted' && integrationStatus !== 'notSynchronized';

  return (
    <>
      {apiError.errorMessage && <ErrorBox errorMessage={apiError.errorMessage} onClose={() => setApiError({})} />}

      <InfoCard
        img={omieLogo}
        ImageProps={{
          sx: { height: '120px', width: '120px' },
        }}
        linkTitle="Saiba mais"
        linkHref="http://help.produttivo.com.br/pt-BR/articles/10508202-como-configurar-a-integracao-com-a-omie"
        linkOptions={{
          targetBlank: true,
          thirdPartyLink: true,
        }}
        headerComponent={headerComponentLayout[integrationStatus] && headerComponentLayout[integrationStatus]()}
        bodyComponent={
          <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px', flexWrap: 'wrap' }}>
            <MainTooltip title={configureButtonDisabledTitle}>
              <Box sx={{ display: 'inline-block' }}>
                <Button
                  variant="contained"
                  size="medium"
                  color="success"
                  onClick={() => updateAuthModal(true)}
                  disabled={!configureButtonEnable}
                >
                  Configurar
                </Button>
              </Box>
            </MainTooltip>

            {canSeeVisibleAndDisconnectButtons && (
              <>
                <MainTooltip title={!canOpenVisualizeModal ? loadingDisabledText : undefined}>
                  <Box sx={{ display: 'inline-block' }}>
                    <Button
                      id="omie-visualize-modal-btn"
                      variant="outlined"
                      size="medium"
                      color="success"
                      disabled={!canOpenVisualizeModal}
                      onClick={handleVisualizeButtonClick}
                    >
                      Visualizar
                    </Button>
                  </Box>
                </MainTooltip>

                <MainTooltip title={!permissions?.disconnectIntegration?.available ? loadingDisabledText : undefined}>
                  <Box sx={{ display: 'inline-block' }}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="success"
                      onClick={() => updateDisconnectModal(true)}
                      disabled={!permissions?.disconnectIntegration?.available}
                    >
                      Desconectar
                    </Button>
                  </Box>
                </MainTooltip>
              </>
            )}
          </Box>
        }
      />
      <AuthModal
        modalOpen={showAuthModal}
        onClose={(v) => updateAuthModal(v)}
        onSuccess={(resp) => updateDataAfterAPIRequestAndAllowPooling(resp)}
        error={integrationStatus === 'notSynchronizedWithErrors'}
        apiUrl={permissions?.configureIntegration?.href}
        apiMethod={permissions?.configureIntegration?.type}
        appKey={data?.omieAuthKey}
        appSecret={data?.omieAuthSecret}
        billingOn={data?.billingOn}
      />
      <SynchronizationModal modalOpen={showSynchronizationModal} onClose={(v) => updateSynchronizationModal(v)} />
      <SyncErrorsModal
        modalOpen={showSyncErrorsModal}
        onClose={(v) => updateSyncErrorsModal(v)}
        onSuccess={(resp) => updateDataAfterAPIRequestAndAllowPooling(resp)}
        tryAgainApiMethod={permissions?.retrySync?.type}
        tryAgainApiUrl={permissions?.retrySync?.href}
        errorsHref={{
          recentErrorHref: permissions?.recentErrorsList?.href,
          customerErrorHref: permissions?.customerErrorsList?.href,
          partErrorHref: permissions?.partErrorsList?.href,
          serviceErrorHref: permissions?.serviceErrorsList?.href,
          workErrorHref: permissions?.workErrorsList?.href,
        }}
      />
      <DisconnectModal
        modalOpen={showDisconnectModal}
        onClose={(v) => updateDisconnectModal(v)}
        onSuccess={(resp) => updateDataAfterAPIRequestAndAllowPooling(resp)}
        apiUrl={permissions?.disconnectIntegration?.href}
        apiMethod={permissions?.disconnectIntegration?.type}
      />
    </>
  );
};

export default OmieCard;
