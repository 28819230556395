import React, { useState } from 'react';
import { Box } from '@mui/material';
import contaAzulLogo from '@/images/conta_azul_logo.png';
import InfoBox from '../../../components/shared/boxes/InfoBox';
import MainSubtitle from '../../../components/shared/typography/MainSubtitle';
import Paragraph from '../../../components/shared/typography/Paragraph';
import InfoCard from '../../../components/shared/boxes/InfoCard';
import UpcomingFeatureBox from '../../../components/shared/boxes/UpcomingFeatureBox';
import OmieCard from './omie/OmieCard';

const contactIntercom = () => {
  // Com uma possível mudança do front-end, a conexão com Intercom precisará ser investigada.
  Intercom(
    'showNewMessage',
    'Olá! Tenho interesse em contratar o módulo “Integração com o ERP”. Poderia me informar quais as informações necessárias?'
  );
};

const ERPSection = () => {
  const [contractedModule, updateContractedModule] = useState(undefined);

  const handleUpdateContractedModule = (moduleName) => {
    const cannotUpdateModuleName = contractedModule && contractedModule !== 'none' && moduleName === 'none';

    if (!moduleName || cannotUpdateModuleName) {
      return;
    }

    updateContractedModule(moduleName);
  };

  const mainBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  };

  return (
    <Box sx={mainBoxSx}>
      {contractedModule && contractedModule === 'none' && (
        <InfoBox
          title={'Contrate o módulo “Integração com ERP” para conectar sua conta com outros sistemas.'}
          link={{ title: 'Contratar', onClick: () => contactIntercom() }}
        />
      )}

      <Box>
        <MainSubtitle sx={{ marginBottom: '8px' }}>Integração com ERP</MainSubtitle>
        <Paragraph>
          É possível integrar o Produttivo com dois dos principais ERPs SaaS utilizados no mercado para resolver a perda
          de tempo ao lançar informações em outro sistema para faturar e controlar estoque.
        </Paragraph>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <OmieCard handleUpdateContractedModule={handleUpdateContractedModule} />
        <InfoCard
          img={contaAzulLogo}
          ImageProps={{
            sx: { height: '120px', width: '120px' },
          }}
          bodyComponent={
            <Box sx={{ flex: 1, display: 'block' }}>
              <Box>
                <UpcomingFeatureBox
                  title="Em construção! Estamos trabalhando para possibilitar a integração com a Conta Azul."
                  link={{ title: 'Tenho interesse', href: 'https://produttivo.typeform.com/to/itRouzhO' }}
                />
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default ERPSection;
