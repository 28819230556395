import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Divider, DialogContent, Box, DialogActions, Button } from '@mui/material';

import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';

import Paragraph from '../../../../components/shared/typography/Paragraph';
import ConfirmationDialog from '../../../../components/shared/modals/ConfirmationDialog';
import ErrorBox from '../../../../components/shared/boxes/ErrorBox';

interface DisconnectModalProps {
  modalOpen: boolean;
  apiUrl: string;
  apiMethod: string;
  onClose: (s: boolean) => void;
  onSuccess: (responseJson: any) => void;
}

const DisconnectModal: React.FC<DisconnectModalProps> = ({ modalOpen, onClose, apiUrl, apiMethod, onSuccess }) => {
  const [openConfirmationDialog, updateConfirmationDialogVisibility] = useState<boolean>(false);
  const [apiErrorMessage, updateApiErrorMessage] = useState<undefined | string>();

  const disconnectIntegration = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: apiMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();

      if (!response.ok) {
        const errorMessage = `Error ${response.status}: ${responseJson.error}`;
        updateConfirmationDialogVisibility(false);
        return updateApiErrorMessage(errorMessage);
      }
      updateConfirmationDialogVisibility(false);
      onClose(false);
      onSuccess(responseJson);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateConfirmationDialogVisibility(false);

    return () => {
      updateConfirmationDialogVisibility(false);
    };
  }, []);

  return (
    <Dialog fullWidth open={modalOpen} onClose={() => onClose(false)}>
      <DialogTitle sx={{ padding: '16px', textAlign: 'center' }}>Desconectar integração com ERP</DialogTitle>

      <Divider />

      <DialogContent
        sx={{
          display: 'flex',
          maxWidth: '460px',
          margin: 'auto',
          padding: '16px',
          flexDirection: 'column',
          gap: '8px',
          justifyContent: 'center',
        }}
      >
        {apiErrorMessage && (
          <ErrorBox errorMessage={apiErrorMessage} onClose={() => updateApiErrorMessage(undefined)} />
        )}

        <Box>
          <Paragraph variant="h6">
            <strong>Ao desconectar sua conta Produttivo do sistema ERP nenhum dado será excluído.</strong>
          </Paragraph>
          <Paragraph>
            Todos os cadastros e atividades que estavam de alguma forma integrados, continuarão na sua conta do
            Produttivo e do ERP.
          </Paragraph>
        </Box>

        <Box sx={{ display: 'flex', gap: '8px' }}>
          <SyncDisabledIcon color="error" />
          <Box>
            <Paragraph>
              <strong>O que muda?</strong>
            </Paragraph>
            <Paragraph>
              A partir de agora qualquer inclusão, alteração ou exclusão não será refletida automaticamente entre os
              sistemas.
            </Paragraph>
          </Box>
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ padding: '16px', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button
          id="omie-disconnect-modal-close-btn"
          variant="text"
          size="medium"
          color="error"
          onClick={() => onClose(false)}
          type="button"
          autoFocus={false}
        >
          Cancelar
        </Button>

        <Button
          id="omie-disconnect-modal-disconnect-btn"
          variant="contained"
          size="medium"
          color="success"
          type="button"
          autoFocus={false}
          onClick={() => updateConfirmationDialogVisibility(true)}
          disabled={openConfirmationDialog}
        >
          Desconectar
        </Button>
      </DialogActions>

      <ConfirmationDialog
        modalOpen={openConfirmationDialog}
        onClose={(v) => updateConfirmationDialogVisibility(v)}
        onSubmit={() => disconnectIntegration()}
        confirmationBtnTitle={'Desconectar'}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Paragraph>
            <strong>Tem certeza que deseja desconectar entre os sistemas?</strong>
          </Paragraph>
          <Paragraph>Esse processo não poderá ser desfeito.</Paragraph>
        </Box>
      </ConfirmationDialog>
    </Dialog>
  );
};

export default DisconnectModal;
