import { Typography, TypographyOwnProps } from '@mui/material';
import React from 'react';

interface ParagraphProps {
  children: React.ReactNode;
  sx?: TypographyOwnProps['sx'];
  variant?: TypographyOwnProps['variant'];
}

const Paragraph: React.FC<ParagraphProps> = ({ children, sx, variant }) => {
  const typographySx = {
    color: '#333',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    fontFamily: 'Roboto',
    ...sx,
  };

  return (
    <Typography variant={variant} sx={typographySx}>
      {children}
    </Typography>
  );
};

export default Paragraph;
