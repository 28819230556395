import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paragraph from '../../../../components/shared/typography/Paragraph';

interface reportErrorsInterface {
  referenceHref?: string;
  referenceTitle?: string;
  errorOrigin?: string;
  lastIntegrationMessage: string;
  id: number;
}

type reportErrorsType = reportErrorsInterface[] | undefined;

interface ErrorTableProps {
  tableTitle: string;
  entityName: string;
  apiUrl: string;
}

const ErrorTable: React.FC<ErrorTableProps> = ({ tableTitle, entityName, apiUrl }) => {
  const [reportErrors, updateReportErrors] = useState<reportErrorsType>();

  useEffect(() => {
    const fetchData = async (url) => {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();

      if (!response.ok) {
        const errorMessage = `Error ${response.status}: ${responseJson.error}`;
        return console.error(errorMessage);
      }

      const apiResults = responseJson.results as [] | undefined;
      const dataResult: reportErrorsType =
        apiResults &&
        apiResults.map((result) => {
          return {
            referenceHref: result['reference_href'] as string | undefined,
            referenceTitle: result['reference_title'] as string | undefined,
            errorOrigin: result['error_origin'] as string | undefined,
            lastIntegrationMessage: result['last_integration_message'] as string,
            id: result['id'] as number,
          };
        });

      updateReportErrors(dataResult);
    };

    fetchData(apiUrl);
    return () => {
      updateReportErrors(undefined);
    };
  }, []);

  return (
    <Box>
      <Paragraph variant="h3">
        <strong>{tableTitle}</strong>
      </Paragraph>
      <Paper>
        <TableContainer
          aria-label="tabela de status de itens sincronizados no Produttivo e na Omie"
          sx={{ maxWidth: '896px', margin: '8px 0 32px' }}
        >
          <Table sx={{ border: '1px solid #D2D2D2' }}>
            <TableHead>
              <TableRow>
                <TableCell>Origem do erro</TableCell>
                <TableCell>Mensagem de erro</TableCell>
                <TableCell>Referência no Produttivo</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {reportErrors &&
                reportErrors.map((omieError, index) => (
                  <TableRow
                    key={`omie-${entityName}-error-${omieError.id}-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{omieError.errorOrigin || '-'}</TableCell>
                    <TableCell>{omieError.lastIntegrationMessage}</TableCell>
                    <TableCell>
                      {omieError.referenceHref && omieError.referenceTitle ? (
                        <Link color="info.main" href={omieError.referenceHref} target="_blank">
                          {omieError.referenceTitle}
                        </Link>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ErrorTable;
