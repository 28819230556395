import { Box, Link, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

interface InfoBoxProps {
  title: string;
  link:
    | {
        title: string;
        href?: string;
        onClick?: React.MouseEventHandler;
      }
    | null
    | undefined;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, link }) => {
  const infoBoxSx = {
    bgcolor: '#F1F9FF',
    borderRadius: '8px',
    border: '1px solid #BBE1FF',
    display: 'flex',
    padding: '24px',
    alignItems: 'flex-start',
    gap: '8px',
  };

  const titleSx = {
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const otherLinkProps = {};

  if (link && typeof link.onClick !== 'undefined') {
    otherLinkProps['component'] = 'button';
    otherLinkProps['onClick'] = link.onClick;
  }

  return (
    <Box sx={infoBoxSx}>
      <InfoIcon sx={{ color: '#1D64F3' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
        <Typography sx={titleSx}>{title}</Typography>
        {link && (
          <Link href={link.href} sx={{ marginTop: '16px', color: '#1D64F3', fontWeight: '700' }} {...otherLinkProps}>
            {link.title}
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default InfoBox;
