import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import Section from './SectionTab';
import BetaTag from '../others/BetaTag';

interface SectionWithTabsProps {
  tabs: [
    {
      value: string;
      label: string;
      component: React.ReactNode;
      beta?: boolean;
    }
  ];
  tabsHidden?: boolean;
}

const SectionWithTabs: React.FC<SectionWithTabsProps> = ({ tabs, tabsHidden }) => {
  const tabsSx = {
    color: '#00000',
    '& .MuiTabs-indicator': {
      backgroundColor: '#01A32D',
    },
  };

  function tabProps(value: string | number) {
    return {
      id: `integration-tab-${value}`,
      'aria-controls': `integration-tabpanel-${value}`,
    };
  }

  function tabLabel(label: string, beta?: boolean) {
    return (
      <Box>
        {label}
        {beta && <BetaTag />}
      </Box>
    );
  }

  const urlHash = window.location.hash?.replace(/^#/, '');
  const urlHashIndex = tabs.findIndex((t) => t.value === urlHash);
  const selectedSectionIndex = urlHashIndex >= 0 ? urlHashIndex : 0;

  const [currentTab, setCurrentTab] = useState(selectedSectionIndex);

  const handleChange = (_: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} hidden={tabsHidden}>
        <Tabs sx={tabsSx} centered={true} value={currentTab} onChange={handleChange} aria-label="Seções de Intgrações">
          {tabs.map((tab, index) => {
            return (
              <Tab
                sx={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: '14px',
                  'text-transform': 'none',
                  '&.Mui-selected': { color: 'rgba(0, 0, 0, 0.87)' },
                }}
                label={tabLabel(tab.label, tab.beta)}
                value={index}
                key={index}
                {...tabProps}
              />
            );
          })}
        </Tabs>
      </Box>

      {tabs.map((tab, index) => {
        return (
          <Section key={tab.value} currentValue={currentTab} index={index}>
            {tab.component}
          </Section>
        );
      })}
    </Box>
  );
};

export default SectionWithTabs;
