import React from 'react';
import { Box, Typography } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import SecondaryLink from '../links/SecondaryLink';

interface UpcomingFeatureBoxProps {
  title: string;
  link:
    | {
        title: string;
        href?: string;
        onClick?: React.MouseEventHandler;
      }
    | null
    | undefined;
}

const UpcomingFeatureBox: React.FC<UpcomingFeatureBoxProps> = ({ title, link }) => {
  const UpcomingFeatureBoxSx = {
    bgcolor: '#FFFFF1',
    borderRadius: '8px',
    border: '1px solid #FBE69F',
    display: 'flex',
    padding: '24px',
    alignItems: 'flex-start',
    gap: '8px',
    width: '100%',
    flex: 1,
  };

  const titleSx = {
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const otherLinkProps = {
    ':hover, :active, :focus': {},
    targetBlank: true,
    thirdPartyLink: true,
  };

  if (link && typeof link.onClick !== 'undefined') {
    otherLinkProps['component'] = 'button';
    otherLinkProps['onClick'] = link.onClick;
  }

  return (
    <Box sx={UpcomingFeatureBoxSx}>
      <DrawIcon sx={{ color: '#F2BC0B' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
        <Typography sx={titleSx}>{title}</Typography>
        {link && (
          <SecondaryLink
            href={link.href}
            sx={{ marginTop: '16px', fontWeight: '700', color: '#F2BC0B' }}
            {...otherLinkProps}
          >
            {link.title}
          </SecondaryLink>
        )}
      </Box>
    </Box>
  );
};

export default UpcomingFeatureBox;
