import React, {
  ChangeEvent,
  ChangeEventHandler,
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Box,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import Paragraph from '../../../../components/shared/typography/Paragraph';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import SecondaryLink from '../../../../components/shared/links/SecondaryLink';
import MainTooltip from '../../../../components/shared/others/MainTooltip';
import ErrorBox from '../../../../components/shared/boxes/ErrorBox';

interface AuthModalProps {
  modalOpen: boolean;
  onClose: (s: boolean) => void;
  onSuccess: (responseJson: any) => {};
  error?: boolean;
  appKey?: string;
  appSecret?: string;
  billingOn?: 'finish_work' | 'review_work';
  omieStep?: 'third';
  apiUrl: string;
  apiMethod: string;
}

type formDataProps = {
  appKey?: string;
  appSecret?: string;
  billingOn?: string;
  omieStep?: string;
};

const AuthModal: React.FC<AuthModalProps> = ({ ...props }) => {
  const { modalOpen, onClose, onSuccess, apiUrl, apiMethod, appKey, appSecret, billingOn, omieStep, error } = props;
  const [showAppKey, updateAppKeyVisibility] = useState<boolean>(false);
  const [showAppSecret, updateAppSecretVisibility] = useState<boolean>(false);
  const [apiError, updateApiError] = useState<boolean>(false);
  const [apiErrorMessage, updateApiErrorMessage] = useState<undefined | string>();
  const [formData, updateFormData] = useState<formDataProps>({
    appKey: appKey,
    appSecret: appSecret,
    billingOn: billingOn || 'review_work',
    omieStep: omieStep || 'third',
  });
  const [saving, updateSaving] = useState<boolean>(false);

  useEffect(() => {
    updateApiError(Boolean(error));

    updateFormData({ ...formData, appKey: appKey, appSecret: appSecret });

    return () => {
      updateApiError(Boolean(error));
      updateSaving(false);
      updateAppKeyVisibility(false);
      updateAppSecretVisibility(false);
    };
  }, [error, appKey, appSecret, billingOn, omieStep]);

  const defineAPIData = () => {
    return JSON.stringify({
      omie_auth_secret: formData?.appSecret,
      omie_auth_key: formData?.appKey,
      generate_billing_on: formData?.billingOn,
    });
  };

  const authIntegration = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: apiMethod,
        body: defineAPIData(),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const contentType = response.headers.get('content-type');

      if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      const responseJson = await response.json();
      updateSaving(false);

      if (!response.ok) {
        const errorMessage = `Error ${response.status}: ${responseJson.error}`;
        return updateApiErrorMessage(errorMessage);
      }

      onClose(false);
      onSuccess(responseJson);
    } catch (error) {
      updateApiErrorMessage('Erro de comunicação com servidor, tente novamente mais tarde ou comunique nosso suporte');
      updateSaving(false);
      console.error(error);
    }
  };

  function handleSubmit(e: FormEvent) {
    updateSaving(true);
    authIntegration();
    e.preventDefault();
  }

  function handleTextInputChange(e: ChangeEvent) {
    const target = e.target as HTMLTextAreaElement;

    const inputById = {
      'omie-app-key': 'appKey',
      'omie-app-secret': 'appSecret',
      'omie-billing-on-select': 'billingOn',
      'omie-os-step-select': 'omieStep',
    };

    const targetKey: 'appKey' | 'appSecret' | 'billingOn' | 'omieStep' | undefined = inputById[target.id];

    if (targetKey) {
      const updatedFormData: formDataProps = { ...formData };
      updatedFormData[targetKey] = `${target.value}`;
      updateFormData(updatedFormData);
      updateApiError(false);
      updateSaving(false);
    }
  }

  function handleWorkStatusSelectChange(e: SelectChangeEvent) {
    const target = e.target as HTMLTextAreaElement;

    const updatedFormData: formDataProps = { ...formData };
    updatedFormData.billingOn = `${target.value}`;
    updateFormData(updatedFormData);
    updateSaving(false);
  }

  function handleOmieStepSelectChange(e: SelectChangeEvent) {
    const target = e.target as HTMLTextAreaElement;

    const updatedFormData: formDataProps = { ...formData };
    updatedFormData.omieStep = `${target.value}`;
    updateSaving(false);
    updateFormData(updatedFormData);
  }

  const appKeyError = apiError || formData.appKey === '';
  const appSecretError = apiError || formData.appSecret === '';
  const inputErrorHelperText = 'Dado inválido. Revise e tente novamente.';
  const cannotSubmitForm = apiError || Boolean(Object.values(formData).findIndex((val) => !val) >= 0) || saving;
  const appKeyVisibilityTitle = showAppKey ? 'Ocultar' : 'Visualizar';
  const appSecretVisibilityTitle = showAppSecret ? 'Ocultar' : 'Visualizar';

  return (
    <Dialog fullWidth={true} open={modalOpen} onClose={() => onClose(false)}>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ padding: '16px', textAlign: 'center' }}>Dados para autenticação</DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: '16px' }}>
          {apiErrorMessage && (
            <Box marginBottom={'8px'}>
              <ErrorBox errorMessage={apiErrorMessage} onClose={() => updateApiErrorMessage(undefined)} />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              justifyContent: 'center',
              maxWidth: '424px',
            }}
          >
            <Paragraph variant="h3">
              <strong>Chave de Integração</strong>
              <MainTooltip
                title={
                  <>
                    As chaves de integração são os códigos responsáveis por conectar os dois sistemas.
                    <br />
                    <br />
                    Você encontra essa informacão na página ”Resumo do App" da sua conta na Omie.
                  </>
                }
              >
                <HelpOutlinedIcon sx={{ marginLeft: '4px', fontSize: '16px', color: '#9B9B9B' }} />
              </MainTooltip>
            </Paragraph>

            <Box marginTop={'8px'}>
              <TextField
                label="APP_KEY"
                id="omie-app-key"
                variant="standard"
                error={appKeyError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                autoComplete="off"
                onChange={handleTextInputChange}
                value={formData.appKey || ''}
                helperText={appKeyError ? inputErrorHelperText : undefined}
                InputProps={{
                  type: showAppKey ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <MainTooltip title={appKeyVisibilityTitle} placement="right-start">
                        <IconButton
                          aria-label={showAppKey ? 'visualizar APP_KEY' : 'ocultar APP_KEY'}
                          onClick={() => updateAppKeyVisibility(!showAppKey)}
                        >
                          {showAppKey ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </MainTooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box margin={'16px 0 40px'}>
              <TextField
                label="APP_SECRET"
                id="omie-app-secret"
                variant="standard"
                error={appSecretError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                autoComplete="off"
                onChange={handleTextInputChange}
                value={formData.appSecret || ''}
                helperText={appSecretError ? inputErrorHelperText : undefined}
                InputProps={{
                  type: showAppSecret ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <MainTooltip title={appSecretVisibilityTitle} placement="right-start">
                        <IconButton
                          aria-label={showAppSecret ? 'visualizar APP_SECRET' : 'ocultar APP_SECRET'}
                          onClick={() => updateAppSecretVisibility(!showAppSecret)}
                        >
                          {showAppSecret ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </MainTooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Paragraph variant="h3">
              <strong>Parâmetros de Criação de OS na Omie</strong>
              <MainTooltip
                title={
                  <>
                    Os parâmetros transformam sua Atividade do Produttivo em uma Ordem de Serviço na Omie
                    automaticamente.
                    <br />
                    <br />
                    O campo "Status da atividade" define quando o Produttivo deve enviar uma Atividade como uma OS para
                    a Omie.
                    <br />
                    <br />O campo "Etapa do fluxo" define onde essa nova OS será criada dentro da Omie.
                  </>
                }
              >
                <HelpOutlinedIcon sx={{ marginLeft: '4px', fontSize: '16px', color: '#9B9B9B' }} />
              </MainTooltip>
            </Paragraph>

            <Box marginTop={'8px'} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="omie-billing-on-label" shrink>
                  Status da atividade do Produttivo
                </InputLabel>
                <Select
                  labelId="omie-billing-on-label"
                  id="omie-billing-on-select"
                  value={formData.billingOn}
                  onChange={handleWorkStatusSelectChange}
                >
                  <MenuItem value={'review_work'}>Revisado</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <ArrowDownwardIcon sx={{ width: '24px', height: '24px', color: '#9B9B9B' }} />
              </Box>
              <FormControl fullWidth variant="standard">
                <InputLabel id="omie-os-step-label" shrink>
                  Etapa do fluxo na Omie
                </InputLabel>
                <Select
                  labelId="omie-os-step-label"
                  id="omie-os-step-select"
                  value={formData.omieStep}
                  onChange={handleOmieStepSelectChange}
                >
                  <MenuItem value={'third'}>Terceira etapa</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box marginTop={'32px'}>
              <SecondaryLink href="http://help.produttivo.com.br/pt-BR/articles/10508202-como-configurar-a-integracao-com-a-omie"
                targetBlank thirdPartyLink
              >
                Dúvidas? Confira o passo a passo para a integração
              </SecondaryLink>
            </Box>
          </Box>
        </DialogContent>

        <Divider />
        <DialogActions sx={{ padding: '16px', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            variant="text"
            size="medium"
            color="error"
            onClick={() => onClose(false)}
            type="button"
            autoFocus={false}
          >
            Cancelar
          </Button>

          <MainTooltip title={cannotSubmitForm ? 'Preencha todas as informações para concluir' : undefined}>
            <Box sx={{ display: 'inline-block' }}>
              <Button
                variant="contained"
                size="medium"
                color="success"
                type="submit"
                autoFocus={false}
                disabled={cannotSubmitForm}
              >
                Salvar
              </Button>
            </Box>
          </MainTooltip>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AuthModal;
